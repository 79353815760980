import { useCallback, MutableRefObject, useState, ChangeEvent, useEffect } from 'react';
import { SessoesInstancia } from 'modulos/cardapio/componentes/produtos';
import { CategoriaCarroselInstancia } from 'modulos/cardapio/componentes/categorias/categorias-carrosel/categorias-carrosel-instancia';
import { isEmpty } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { useObterSacola } from 'storage';
import { useHistory } from 'react-router-dom';
import { useNotificacoes, useUsuarioLogado } from 'componentes';
import { ProdutoModel } from 'api/cardapio/models/produto/produto-model';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto/produto-categoria-model';
import { MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { CardapioDadosProps } from '../cardapio-header/cardapio-header-props';

export enum EnumTipoScanner {
  VisualizarPedido,
  PrepararPedido
}

export interface CardapioLogicaProps {
  sessoes: MutableRefObject<SessoesInstancia | null>
  categoria: MutableRefObject<CategoriaCarroselInstancia | null>
  pesquisa: MutableRefObject<HTMLDivElement | null>
  menuNavRef: MutableRefObject<HTMLDivElement | null>
  produtos: ProdutoCategoriaModel[]
  operacoesApi: any
  empresaId: string
  mesaId: string
  somenteLeitura: boolean
  cardapioDados: CardapioDadosProps | undefined
}

export const useCardapioLogica = ({ categoria, cardapioDados, empresaId, menuNavRef, mesaId, operacoesApi, pesquisa, produtos, sessoes, somenteLeitura }: CardapioLogicaProps) => {
  const history = useHistory();
  const [termoPesquisa, definirTermoPesquisa] = useState('');
  const [resultadoPesquisa, definirResultadoPesquisa] = useState<Array<ProdutoModel>>(new Array<ProdutoModel>());
  const [carregandoPesquisa, definirCarregandoPesquisa] = useState(false);
  const [categoriaSendoExibida, definirCategoriaExibida] = useState<string | undefined>(undefined);
  const [produto, setProduto] = useState<MovSimplesProdutoModel>(new MovSimplesProdutoModel());
  const [categoriaSelecionada, definirCategoriaSelecionada] = useState<string | undefined>(
    undefined,
  );
  const [formaPagModal, setFormaPagModal] = useState<boolean>(false)
  const [pagPixModal, setPagPixModal] = useState<boolean>(false)
  const [modalCliente, setModalCliente] = useState(false);
  const [dialogAutenticar, setDialogAutenticar] = useState<boolean>(false);
  const [sacola, setSacola] = useState(false);
  const [garcom, setGarcom] = useState(false);
  const [abriuSacola, setAbriuSacola] = useState(false);
  const [scannerComanda, setScannerComanda] = useState(false);
  const [tipoScanner, setTipoScanner] = useState<EnumTipoScanner>(EnumTipoScanner.PrepararPedido)
  const pesquisando = isEmpty(termoPesquisa) === false;
  const [produtoAberto, setProdutoAberto] = useState<boolean>(false)
  const [dialogSubitem, setDialogSubItem] = useState<boolean>(false)
  const [mostrarCategoriaHeader, setMostrarCategoriaHeader] = useState<boolean>(false)

  const { showErrorMessage, showWarningMessage } = useNotificacoes()

  const { horarioFuncionamento, empresa } = cardapioDados!

  const itensSacola = useObterSacola(empresaId);

  const { deslogarUsuario } = useUsuarioLogado();

  const countSacola = useCallback(() => {
    if (somenteLeitura) {
      if (itensSacola?.data?.length === 1 && !abriuSacola) {
        setSacola(true);
        setAbriuSacola(true);

      } else {
        if (itensSacola?.data?.length === 0 && !abriuSacola) {
          setAbriuSacola(false);
        }
      }
    }
  }, [itensSacola?.data?.length, somenteLeitura, abriuSacola]);

  useEffect(() => {
    countSacola();
  }, [itensSacola, countSacola]);

  const validarTelefone = useCallback((valor: string) => {
    if (isEmpty(valor))
      return '.';

    return `, ou entre em contato conosco pelo telefone ${valor}.`;
  }, [])

  const validarRestauranteAberto = useCallback(() => {
    let isAberto;
    let dataAtual = new Date();
    let horaAtual = `${dataAtual
      .getHours()
      .toString()
      .padStart(2, '0')}:${dataAtual
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${dataAtual.getSeconds().toString().padStart(2, '0')}`;

    if (horarioFuncionamento?.empresaHorarioDias === null || horarioFuncionamento?.empresaHorarioDias === undefined)
      showErrorMessage(`Erro ao buscar o horário de funcionamento da loja. Detalhe: Erro de comunicação com o servidor, tente novamente em alguns instantes${validarTelefone(empresa?.fone!)}`);

    else if (horarioFuncionamento?.empresaHorarioDias?.length! < 1) {
      showWarningMessage('A loja está fechada.');
    }

    else {
      let horariosDias = horarioFuncionamento?.empresaHorarioDias.filter(x => x.diaSemana === dataAtual.getDay());

      if (!horariosDias || horariosDias.length < 1) {
        isAberto = false;
        showWarningMessage('A loja está fechada.');
      }
      else if (horariosDias[0].inicio && horariosDias![0].inicio <= horaAtual
        && horariosDias[0].fim &&
        horariosDias![0].fim > horaAtual)
        isAberto = true
      else {
        isAberto = false;
        showWarningMessage('A loja está fechada.');
      }
    }
    return isAberto;
  }, [empresa?.fone, horarioFuncionamento?.empresaHorarioDias, showErrorMessage, showWarningMessage, validarTelefone]);

  const { ref: header, inView: headerEstaSendoVisualizado } = useInView({
    threshold: 0,
    initialInView: true,
  });

  const abrirAdicaoProduto = useCallback((sessao?: ProdutoCategoriaModel) => {
    definirCategoriaSelecionada(sessao?.id);
  }, []);

  const fecharEdicaoProduto = useCallback(() => {
    setProdutoAberto(false)
    definirCategoriaSelecionada(undefined);
  }, []);


  const abrirEdicaoProduto = useCallback((produto: MovSimplesProdutoModel) => {
    if (validarRestauranteAberto()) {
      setProduto(produto!);
      if (produto.subItens.length === 0) {
        setProdutoAberto(true)
        return
      }
      setDialogSubItem(true)
    }
  }, [validarRestauranteAberto]);

  const quandoSessaoForExibida = useCallback((sessao?: ProdutoCategoriaModel) => {
    definirCategoriaExibida(sessao?.id);
  }, []);

  const categoriaClick = useCallback(
    (categoriaId: string) => {
      sessoes.current?.rolarParaSessao(categoriaId);
    },
    [sessoes],
  );

  const quandoTermoForDigitado = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      definirTermoPesquisa(event.target.value);
    },
    [],
  );

  const limparTermoPesquisa = useCallback(() => {
    definirTermoPesquisa('');
  }, []);

  const buscarClick = useCallback(() => {
    if (pesquisando === false) {
      return;
    }

    const pesquisaScrollInterval = setInterval(() => {
      if (!pesquisa?.current) {
        return;
      }

      clearInterval(pesquisaScrollInterval);

      window.scrollTo({
        behavior: 'smooth',
        top: (pesquisa?.current?.offsetTop || 0) - (menuNavRef?.current?.offsetHeight || 0) - 10,
      });
    }, 50);
  }, [pesquisa, pesquisando, menuNavRef]);

  const quandoTermoForDigitadoNoNavbar = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      definirTermoPesquisa(event.target.value);

      setTimeout(() => {
        buscarClick();
      }, 200);
    },
    [buscarClick],
  );

  useEffect(() => {
    definirCarregandoPesquisa(true);

    const timeoutId = setTimeout(() => {
      const resultado = sessoes?.current?.pesquisarSessoes(termoPesquisa);

      if (resultado) {
        definirResultadoPesquisa(resultado);
      }

      definirCarregandoPesquisa(false);
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [termoPesquisa, sessoes, produtos]);

  const abrirSacola = useCallback(() => {
    setSacola(true);
  }, []);

  const fecharSacola = useCallback(() => {
    setSacola(false);
  }, []);

  const abrirScannerComanda = useCallback(() => {
    setScannerComanda(true);
  }, [])


  const fecharScannerComanda = useCallback(() => {
    setScannerComanda(false);
  }, [])

  const abrirGarcom = useCallback(() => {
    setGarcom(true);
  }, [])

  const fecharGarcom = useCallback(() => {
    setGarcom(false);
  }, [])

  const abrirModalCliente = useCallback(() => {
    setModalCliente(true);
  }, [])

  const fecharModalCliente = useCallback(() => {
    setModalCliente(false);
  }, []);

  const abrirModalFormaPag = useCallback(() => {
    setFormaPagModal(true)
  }, [])

  const fecharModalFormaPag = useCallback(() => {
    setFormaPagModal(false)
  }, [])

  const abrirModalPagPix = useCallback(() => {
    setPagPixModal(true)
  }, [])

  const fecharModalPagPix = useCallback(() => {
    setPagPixModal(false)
  }, [])

  const definirTipoScanner = useCallback((tipo: EnumTipoScanner) => {
    setTipoScanner(tipo)
  }, [])

  const navegarConfigurando = useCallback(() => {
    history.push(`/${empresaId}/configuracoes`);
  }, [empresaId, history]);

  const navegarParaMesa = useCallback(() => {
    history.push(`/${empresaId}/${mesaId}/mesa`)
  }, [history, empresaId, mesaId])

  const navegarQrCode = useCallback(() => {
    history.push(`/${empresaId}/gerar-qrcode`);
  }, [empresaId, history])

  const navegarParaEdicao = useCallback(() => {
    history.push(`/${empresaId}/editar`);
  }, [empresaId, history]);

  const logar = useCallback(() => {
    history.push(`/${empresaId}`)
  }, [empresaId, history])

  const logout = useCallback(() => {
    deslogarUsuario();
    history.push(`/${empresaId}`);
  }, [history, deslogarUsuario, empresaId]);

  return {
    operacoes: {
      categoriaClick,
      quandoTermoForDigitado,
      buscarClick,
      quandoTermoForDigitadoNoNavbar,
      quandoSessaoForExibida,
      fecharEdicaoProduto,
      abrirEdicaoProduto,
      abrirAdicaoProduto,
      abrirSacola,
      fecharSacola,
      abrirGarcom,
      fecharGarcom,
      configurandoWrapper: navegarConfigurando,
      gerarQrCode: navegarQrCode,
      navegarParaEdicao,
      navegarParaMesa,
      logout,
      logar,
      limparTermoPesquisa,
      fecharScannerComanda,
      abrirModalCliente,
      fecharModalCliente,
      abrirModalFormaPag,
      fecharModalFormaPag,
      abrirModalPagPix,
      fecharModalPagPix,
      abrirScannerComanda,
      definirTipoScanner,
      setDialogSubItem,
      setDialogAutenticar,
      setMostrarCategoriaHeader,
    },
    dados: {
      termoPesquisa,
      carregandoPesquisa,
      resultadoPesquisa,
      pesquisando,
      header,
      headerEstaSendoVisualizado,
      categoriaSendoExibida,
      produto: produto,
      produtoAberto,
      categoriaSelecionada,
      sacola,
      dialogAutenticar,
      garcom,
      scannerComanda,
      modalCliente,
      formaPagModal,
      pagPixModal,
      tipoScanner,
      dialogSubitem,
      mostrarCategoriaHeader,
    },
  };
};