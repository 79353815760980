import React, { useRef } from "react"
import { DialogSaurus } from "../produtos/dialog-adicionar-produto-com-subitem/dialog-saurus/dialog-saurus"
import { FormLogin } from "./form-login/form-login"
import { LoginFormModel } from "./form-login/model/login-form"
import { useAuth } from "hooks/auth"
import { useNotificacoes } from "componentes"
import { DefaultFormRefs } from "./form-login/model/utils"
import { Button, Grid, Typography } from "@material-ui/core"
import { EnumDialogs, useDialogStore } from "storage/dialog-store"
import { useHistory } from "react-router-dom"
import { convertToken } from "utils/convert-token"
import { useTokenUsuario } from "api/master/queries/token/obter-token-usuario"
import { useMesaStore } from "storage/mesa-store"
import { timeStorage } from "utils"

export interface DialogAutenticarSenhaProps {
    aberto: boolean,
}

export const DialogAutenticarSenha = ({
    aberto,
}: DialogAutenticarSenhaProps) => {
    const { auth, carregando } = useAuth()
    const { showErrorMessage, showSuccessMessage } = useNotificacoes()
    const { TokenValido } = useTokenUsuario();
    const openCloseDialog = useDialogStore(state => state.openCloseDialog);
    const empresaId = useMesaStore(state => state.empresaId);
    const desvincularMesa = useMesaStore(state => state.desvincularMesa);
    const history = useHistory()

    const loginRefs = useRef<DefaultFormRefs<LoginFormModel>>(null);

    const efetuarLogin = async (model: LoginFormModel) => {
        try {
            const res = await auth({
                usuario: model.usuario,
                senha: model.senha,
                contratoId: ''
            })

            if (res.erro) throw res.erro

            const token = res.resultado?.data.accessToken
            const getTokenInfo = convertToken(token)

            if (!getTokenInfo) throw new Error('Erro ao converter token')

            const getFirstEmpresa = getTokenInfo.empresa[0]

            if (token) {
                if (TokenValido(token)) {
                    if (getFirstEmpresa.Id !== empresaId) {
                        desvincularMesa();
                    }
                    timeStorage.save("tokenMasterUsuario", token, 3600)
                    localStorage.setItem("empresaMobile", getFirstEmpresa.Id);
                }
                openCloseDialog(EnumDialogs.Autenticar, false)
                showSuccessMessage('Login efetuado com sucesso')
                history.push(`/${getFirstEmpresa.Id}`)
                return
            }


        } catch (error: any) {
            showErrorMessage(error.message)
        }
    }


    return (
        <DialogSaurus
            aberto={aberto}
            titulo='Autenticação de Administrador'
            isButtonTitleClose
            tamanho="xs"
            handleClickClose={() => openCloseDialog(EnumDialogs.Autenticar, false)}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        Faça login para obter acesso as configurações de administrador.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormLogin
                        showLoading={false}
                        ref={loginRefs}
                        loading={carregando}
                        onSubmit={(model) => {
                            efetuarLogin(model);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth onClick={() => loginRefs.current?.submitForm()} color="primary" size="large" variant="contained">
                        Entrar na Conta
                    </Button>
                </Grid>
            </Grid>
        </DialogSaurus>
    )
}