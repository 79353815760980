import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { MeuMenu, MenuConta, BarraPesquisa, DrawerMenu } from '..';
import { CategoriaTabs } from '../cardapio-categoria-tabs'
import { useMediaQuery, useTheme, AppBar, Container, IconButton, Fade, Grid, Box } from '@material-ui/core';
import { Carregando } from 'componentes';
import { useHistory } from 'react-router-dom';
import { Titulo } from 'componentes';
import { isEmpty } from 'lodash';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useStyles } from './cardapio-header-navbar-styles';
import { isValidUUIDV4 } from 'utils/is-valid-id';
import { ProdutoCategoriaModel } from 'api/cardapio/models/produto-categoria/produto-categoria-model';
import { useTokenUsuario } from 'api/master/queries/token/obter-token-usuario';
import { ButtonResumoPedido } from './../../cardapio-resumo-pedido/components/buttom-resumo-pedido/'
import { usePedidos } from 'componentes/providers/pedidos-provider';
import classNames from 'classnames';
import { useMesaStore } from 'storage/mesa-store';
import { MesaIcon } from 'utils/Icons/mesa-icon';
import { useConfirm } from 'material-ui-confirm';
import { VariaveisAmbiente } from 'ambiente';
import { EnumDeviceType } from 'ambiente/ambiente-tipos';
import { GetAuthLoginRedirect } from 'utils';
import { CardapioDadosProps } from '../../cardapio-header/cardapio-header-props';
import { EnumDialogs, useDialogStore } from 'storage/dialog-store';

export interface CardapioHeaderNavBarProps {
  somenteLeitura: boolean;
  categorias?: Array<ProdutoCategoriaModel>;
  configurando: boolean;
  mostarQrCode: boolean;
  termoPesquisa?: string;
  categoriaSelecionada?: string;
  referencia?: any;
  contextoSelecionado?: string;
  statusPedido: boolean;
  categoriaClick: (categoria: string) => any;
  quandoPesquisar?: (event: ChangeEvent<HTMLInputElement>) => any;
  logout: () => any;
  quandoSacolaClick: () => any;
  quandoGarcomClick: () => any;
  setConfigurando: () => void;
  setGerarQrCode: () => void;
  navegarEditar: () => void;
  limparTermo: () => any;
  logar: () => any;
  setStatusPedido: (value: boolean) => void;
  cardapioDados: CardapioDadosProps | undefined
  mostrarCategoriaHeader: boolean
}

export const CardapioHeaderNavBar = ({
  somenteLeitura,
  categorias,
  configurando,
  mostarQrCode,
  termoPesquisa,
  categoriaSelecionada,
  referencia,
  contextoSelecionado,
  categoriaClick,
  quandoPesquisar,
  logout,
  quandoSacolaClick,
  quandoGarcomClick,
  setConfigurando,
  setGerarQrCode,
  navegarEditar,
  limparTermo,
  logar,
  cardapioDados,
  statusPedido,
  setStatusPedido,
  mostrarCategoriaHeader
}: CardapioHeaderNavBarProps) => {

  const { configuracoes, empresa } = cardapioDados!;
  const mesa = useMesaStore(state => state.mesa);
  const getMesaId = useMesaStore(state => state.getMesaId);
  const { location } = useHistory();
  const mesaId = getMesaId(location.pathname);
  const openCloseDialog = useDialogStore(state => state.openCloseDialog);

  // DEPENDENCIAS
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mesaIdValido = isValidUUIDV4(mesaId);
  const { usuarioToken, RotaValida } = useTokenUsuario()
  const rotaInvalida = !RotaValida(empresa.id)
  const history = useHistory();
  const capa = configuracoes.capa
  const confirm = useConfirm();

  // STATES E STYLES
  const [pesquisa, definirPesquisa] = useState(false);
  const classes = useStyles({ capa, categorias, pesquisa, configurando, mostarQrCode });
  const [carregando, setCarregando] = useState(false);
  const [mostrarCategorias, setMostrarCategorias] = useState(false);
  const { getPedido, pedidoAtual } = usePedidos()

  useEffect(() => {
    getPedido();
  }, [getPedido])

  useEffect(() => {
    setTimeout(() => {
      setMostrarCategorias(
        isEmpty(categorias) === false && Array.isArray(categorias) && !configurando && !mostarQrCode,
      );
    }, 1);
  }, [categorias, somenteLeitura, configurando, mostarQrCode]);

  useEffect(() => {
    if (!rotaInvalida) {
      definirPesquisa(false);
    }
  }, [rotaInvalida]);

  const onVisualizarClick = useCallback(() => {
    history.push(`/${empresa.id}`);
  }, [history, empresa]);

  const onEditarConfiguracoesClick = useCallback(() => {
    setConfigurando();
  }, [setConfigurando]);

  const onGerarQRCode = useCallback(() => {
    setGerarQrCode();
  }, [setGerarQrCode]);

  const onPesquisaClick = React.useCallback(() => {
    definirPesquisa((prev) => !prev);
  }, []);

  const onClickMesa = React.useCallback(() => {
    let title = 'Sem Mesa Vinculada';
    let description = `Você não possuí uma mesa vinculada. Para vincular, leia o QRCode de uma mesa pelo seu dispositivo.
      Para vincular à um dispositivo da loja, contate um administrador.`

    if (mesaId) {
      title = `Mesa ${mesa?.codigo} Vinculada`;
      if (VariaveisAmbiente.deviceType === EnumDeviceType.NAVIGATOR) {
        description = `Você possui uma mesa vinculada. Para vincular outra, leia um QRCode com seu dispositivo.`
      } else if (rotaInvalida) {
        description = `Para vincular outra mesa no dispositivo, contate um administrador.`
      } else {
        description = ``
      }
    }

    confirm({
      title,
      description,
      confirmationText: 'Concluir',
      cancellationText: rotaInvalida ? 'Acessar como Administrador' : 'Configurar',
    }).then(() => { })
      .catch(() => {
        if (rotaInvalida) {
          if (VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA) {
            openCloseDialog(EnumDialogs.Autenticar, true);
          } else {
            window.location.assign(GetAuthLoginRedirect());
          }
        } else {
          openCloseDialog(EnumDialogs.SelecionarMesa, true);
        }
      })
  }, [confirm, mesa?.codigo, mesaId, openCloseDialog, rotaInvalida])

  useEffect(() => {
    if (isEmpty(pedidoAtual)) {
      setStatusPedido(false)
    }
    else {
      setStatusPedido(true);
    }
  }, [pedidoAtual, setStatusPedido])

  const validarBarraPesquisa = useCallback(() => {
    switch (pesquisa) {
      case mesaIdValido:
        return true;
      case somenteLeitura:
        return true;
      case isMobile && mesaIdValido:
        return true;
      case isMobile && rotaInvalida:
        return true;
    }
  }, [mesaIdValido, isMobile, pesquisa, rotaInvalida, somenteLeitura])

  return (
    <>
      <div>
        <AppBar
          ref={referencia}
          position="static"
          className={classes.appBar}
          style={{ transition: 'height 0.3s ease-in-out  !important' }}
          color="default"
          elevation={4}
        >
          <Container fixed style={{
            marginTop: 8,
            marginBottom: 8
          }}>
            <Grid container spacing={2}>
              {isMobile ? (
                <>
                  <>
                    {!rotaInvalida && (
                      <Grid item xs={2}>
                        <DrawerMenu
                          capa={capa}
                          logoUrl={configuracoes.logo}
                          somenteLeitura={somenteLeitura}
                          rotaInvalida={rotaInvalida}
                          setCarregando={setCarregando}
                          gerarQRCode={onGerarQRCode}
                          onEditarConfiguracoesClick={onEditarConfiguracoesClick}
                          onVisualizarClick={onVisualizarClick}
                          logout={logout}
                          abrirSacola={quandoSacolaClick}
                          buscarProds={onPesquisaClick}
                          mesaId={mesaId}
                        />
                      </Grid>
                    )}
                  </>

                  {rotaInvalida && <Grid item xs={VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA ? 5 : 9}>
                    <Box display={"flex"} alignItems={"center"} height={"100%"} paddingY={1}>
                      {Boolean(configuracoes.logo) ? (
                        <img src={configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                      ) : null}
                      <Titulo style={{ fontSize: !mesaIdValido ? '24px' : '16px' }} className={mesaIdValido ? classes.limitarTitulo : ''}>
                        {empresa?.nomeFantasia}
                      </Titulo>
                    </Box>
                  </Grid>}
                  {VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA && <Grid item xs={rotaInvalida ? 4 : 5} className={classes.mesaContainer}>
                    <Box className={classes.mesaButton} onClick={onClickMesa}>
                      <MesaIcon tipo='GERAL' />
                      {mesa ? `Mesa ${mesa.codigo}` : 'Sem Mesa'}
                    </Box>
                  </Grid>}
                  {!mesaIdValido && (
                    <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      <Fade in={!pesquisa} timeout={200}>
                        <IconButton
                          onClick={onPesquisaClick}
                          aria-label="pesquisar"
                          aria-haspopup="true"
                          className={classes.searchIcon}
                          style={{
                            pointerEvents: !pesquisa ? 'all' : 'none',
                            opacity: !pesquisa ? 1 : 0,
                            padding: 4
                          }}
                        >
                          <SearchRoundedIcon style={{ fontSize: '35px' }} />
                        </IconButton>
                      </Fade>
                    </Grid>
                  )}
                  {mesaIdValido && (
                    <>
                      <Grid item xs={rotaInvalida ? 3 : 5}>

                        <Box display='flex'
                          justifyContent={'flex-end'}
                          alignItems='center'
                          gridGap={16}
                        >
                          <Fade in={!pesquisa && !configurando} timeout={200}>
                            <Box
                              onClick={onPesquisaClick}
                              aria-label="pesquisar"
                              aria-haspopup="true"
                              className={classes.searchIcon}
                              style={{
                                pointerEvents: !pesquisa ? 'all' : 'none',
                                opacity: !pesquisa ? 1 : 0,
                                padding: 4
                              }}
                            >
                              <SearchRoundedIcon style={{ fontSize: '25px' }} />
                            </Box>
                          </Fade>
                          <ButtonResumoPedido
                            mesaId={mesaId}
                            empresaId={empresa.id}
                            quandoClicado={quandoGarcomClick}
                            colorBadge="secondary"
                            corLogo="textPrimary"
                            size="35"
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!rotaInvalida && (
                    <>
                      {Boolean(configuracoes.logo) && <Grid item xs={!rotaInvalida ? 2 : mesaIdValido ? 6 : 2} style={{ display: 'flex' }}>
                        <img src={configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                      </Grid>}

                      {/*Barra de Edição, configuração, QRcode e visualizão*/}
                      <Grid item xs={Boolean(configuracoes.logo) ? 7 : 12} style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                        <MeuMenu
                          setCarregando={setCarregando}
                          gerarQRCode={onGerarQRCode}
                          onEditarConfiguracoesClick={onEditarConfiguracoesClick}
                          somenteLeitura={somenteLeitura}
                          contextoSelecionado={contextoSelecionado}
                          onVisualizarClick={onVisualizarClick}
                        />
                        {
                          (!rotaInvalida && !mesaIdValido) && (
                            <Box display='flex' gridGap={2} flexGrow={1} justifyContent='flex-end'>
                              <Fade in={!pesquisa && !configurando} timeout={200}>
                                <Box
                                  onClick={onPesquisaClick}
                                  aria-label="pesquisar"
                                  aria-haspopup="true"
                                  className={classes.searchIcon}
                                  style={{
                                    pointerEvents: !pesquisa ? 'all' : 'none',
                                    opacity: !pesquisa ? 1 : 0,
                                    padding: 4
                                  }}
                                >
                                  <SearchRoundedIcon style={{ fontSize: '25px' }} />
                                </Box>
                              </Fade>
                              <MenuConta
                                logout={logout}
                                rotaInvalida={rotaInvalida}
                                setCarregando={setCarregando}
                                somenteLeitura={somenteLeitura}
                                nomeUsuario={usuarioToken?.saudacao}
                                tema={configuracoes}
                                empresa={empresa}
                              />
                            </Box>
                          )
                        }
                      </Grid>
                    </>
                  )}

                  {mesaIdValido ? (
                    <>
                      <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gridGap='16px'>
                          <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gridGap='8px'>
                            {Boolean(configuracoes.logo) && !(VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA) ? (
                              <img src={configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                            ) : null}
                            <BarraPesquisa
                              pesquisa={false}
                              termoPesquisa={termoPesquisa}
                              onPesquisaClick={onPesquisaClick}
                              quandoPesquisar={quandoPesquisar}
                              limparPesquisa={limparTermo}
                              fixo={true && !configurando}
                              modo="static"
                            />
                          </Box>
                          <ButtonResumoPedido
                            mesaId={mesaId}
                            empresaId={empresa.id}
                            quandoClicado={quandoGarcomClick}
                            colorBadge="secondary"
                            corLogo="textPrimary"
                            size="35"
                          />
                          {VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA && <Box className={classes.mesaButton} onClick={onClickMesa}>
                            <MesaIcon tipo='GERAL' />
                            {mesa ? `Mesa ${mesa.codigo}` : 'Sem Mesa'}
                          </Box>}
                          {(!isMobile && !rotaInvalida) && (
                            <Box display='flex' gridGap={1}>
                              <>
                                <Fade in={!pesquisa} timeout={200}>
                                  {!rotaInvalida ? (
                                    <>
                                      <MenuConta
                                        logout={logout}
                                        rotaInvalida={rotaInvalida}
                                        setCarregando={setCarregando}
                                        somenteLeitura={somenteLeitura}
                                        nomeUsuario={usuarioToken?.saudacao}
                                        tema={configuracoes}
                                        empresa={empresa}
                                      />
                                    </>
                                  ) : (
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gridGap='16px'>
                                      {Boolean(configuracoes.logo) ? (
                                        <img src={configuracoes.logo || ''} className={classNames(classes.logo, classes.logoRounded)} alt="Logotipo do Cliente" />
                                      ) : null}
                                      <BarraPesquisa
                                        pesquisa={false}
                                        termoPesquisa={termoPesquisa}
                                        onPesquisaClick={onPesquisaClick}
                                        quandoPesquisar={quandoPesquisar}
                                        limparPesquisa={limparTermo}
                                        fixo={true && !configurando}
                                        modo="static"
                                      />
                                      {VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA && <Box className={classes.mesaButton} onClick={onClickMesa}>
                                        <MesaIcon tipo='GERAL' />
                                        {mesa ? `Mesa ${mesa.codigo}` : 'Sem Mesa'}
                                      </Box>}
                                    </Box>
                                  )}
                                </Fade>
                              </>
                            </Box>
                          )}
                        </Box>

                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                          <BarraPesquisa
                            pesquisa={false}
                            termoPesquisa={termoPesquisa}
                            onPesquisaClick={onPesquisaClick}
                            quandoPesquisar={quandoPesquisar}
                            limparPesquisa={limparTermo}
                            fixo={true && !configurando}
                            modo="static"
                          />
                          {VariaveisAmbiente.deviceType === EnumDeviceType.CORDOVA && (
                            <Box ml={2} className={classes.mesaButton} onClick={onClickMesa}>
                              <MesaIcon tipo='GERAL' />
                              {mesa ? `Mesa ${mesa.codigo}` : 'Sem Mesa'}
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              )}

            </Grid>
          </Container>

          <Fade in={mostrarCategorias
            // && mostrarCategoriaHeader
          } mountOnEnter unmountOnExit timeout={200}>
            <CategoriaTabs
              categorias={categorias}
              categoriaClick={categoriaClick}
              categoriaSelecionada={categoriaSelecionada}
            />
          </Fade>

          {validarBarraPesquisa() && (
            <BarraPesquisa
              pesquisa={pesquisa && !configurando}
              termoPesquisa={termoPesquisa}
              onPesquisaClick={onPesquisaClick}
              quandoPesquisar={quandoPesquisar}
              limparPesquisa={limparTermo}
              modo="dinamic"
            />
          )}
        </AppBar>
      </div >
      <Carregando
        carregando={carregando}
        modelo="fixed"
        titulo={'Carregando...'}
        mensagem={`Aguarde enquanto carregamos a ${somenteLeitura ? 'edição' : 'visualização'
          } do cardápio...`}
        className={classes.carregando}
      />

    </>
  );
};