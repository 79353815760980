import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import { Titulo, Fab } from 'componentes';
import { CategoriasCarroselProps } from './categorias-carrosel-props';
import { CategoriaCarroselInstancia } from './categorias-carrosel-instancia';
import { useStyles } from './categorias-carrosel-styles';
import { CategoriaCarroselConfig } from './categorias-carrosel-config';
import { useCategoriasCarrosel } from './use-categorias-carrosel';
import { Categoria } from 'modulos/cardapio/componentes/categorias/categoria';
import Slider from 'react-slick';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useMesaStore } from 'storage/mesa-store';
import { useHistory } from 'react-router-dom';

export const CategoriasCarrosel = React.forwardRef<
  CategoriaCarroselInstancia,
  CategoriasCarroselProps
>((props, ref) => {
  const sliderContainer = React.useRef<HTMLDivElement>(null);
  const slider = React.useRef<Slider>(null);
  const { location } = useHistory();
  const categoriaCarrosselRef = React.useRef<HTMLDivElement>(null);

  // ID MESA NA URL
  const getMesaId = useMesaStore(state => state.getMesaId)
  const mesaId = getMesaId(location.pathname);
  const classes = useStyles({ mesaId: mesaId });

  const {
    className,
    carregando = false,
    somenteLeitura,
    // setMostrarCategoriaHeader
  } = props;
  const { dados, operacoes } = useCategoriasCarrosel(props);
  React.useImperativeHandle(ref, () => ({
    editarCategoria: editarWrapper,
  }));

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       setMostrarCategoriaHeader(!entry.isIntersecting);
  //     },
  //     { threshold: 0.1 }
  //   );

  //   if (categoriaCarrosselRef.current) {
  //     observer.observe(categoriaCarrosselRef.current);
  //   }

  //   return () => observer.disconnect();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const editarWrapper = (categoriaId: string) => {
    if (dados.categorias) {
      let categoria = dados.categorias.find((x) => x.id === categoriaId);
      operacoes.editar(categoria);
    }
  };

  if (carregando === false && dados.existeCategorias === false && somenteLeitura) {
    return null;
  }

  const categorias = Array.isArray(dados.categorias)
    ? somenteLeitura
      ? dados.categorias.filter((x) => x.ativo === true)
      : dados.categorias
    : [];

  const catOrdenadas = (categorias || []);


  return (
    <div className={className} ref={categoriaCarrosselRef}>
      <div className={classes.header}>
        <Titulo>Categorias</Titulo>
        {somenteLeitura === false && (
          <Fab
            onClick={operacoes.abrirCadastroCategoria}
            color="primary"
            size="small"
            tooltip="Adicionar Categoria"
          >
            <AddIcon />
          </Fab>
        )}
      </div>

      <div ref={sliderContainer} className={classes.root}>
        {carregando === false && dados.existeCategorias ? (
          <Fab
            onClick={slider.current?.slickPrev}
            color="default"
            size="small"
            className={classes.btnVoltar}
          >
            <ArrowBackIosRoundedIcon />
          </Fab>
        ) : null}
        <div className={classes.slider}>
          <Slider ref={slider} {...CategoriaCarroselConfig}>
            {(carregando ? Array.from(new Array(20)) : catOrdenadas)?.map((categoria, index) => (
              <div key={categoria?.id || index} className={classes.slide}>
                <Categoria
                  carregando={carregando}
                  categoria={categoria}
                  somenteLeitura={somenteLeitura}
                  onClick={operacoes.selecionarCategoria}
                />
              </div>
            ))}
          </Slider>
        </div>
        {carregando === false && dados.existeCategorias ? (
          <Fab
            onClick={slider.current?.slickNext}
            color="default"
            size="small"
            className={classes.btnAvancar}
          >
            <ArrowForwardIosRoundedIcon />
          </Fab>
        ) : null}
      </div>
    </div>
  );
});
